import { PageTitle } from "../../utils/PageTitle";
import { Form, Table } from "react-bootstrap";
import { collection, doc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { User } from "../../types/User";
import { useEffect, useState } from "react";
import { db } from "../../Firebase";
import Spinner from "../../components/spinner/Spinner";
import { dateObjectToDate, timestampToDateObject } from "../../utils/DateConversions";
import { roles } from "../../utils/Roles";

export default function UserManager() {
  PageTitle("Users");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  // Database calls
  useEffect(() => {
    const q = query(collection(db, 'users'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data: User[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        username: doc.data().username,
        approved: doc.data().approved,
        role: doc.data().role,
        dateJoined: timestampToDateObject(doc.data().dateJoined),
        dateLastLogin: timestampToDateObject(doc.data().dateLastLogin)
      }));

      data.sort((a: User, b: User) => (a.username > b.username ? 1 : -1));
      setUsers(data);
      setLoading(false);
    });

    return unsubscribe;
  }, []);



  const handleApprovedChange = async (user: User) => {
    await updateDoc(doc(db, 'users', user.id), {
      approved: !user.approved
    });
  }

  const handleUserRoleChange = async (user: User, roleName: string) => {
    await updateDoc(doc(db, 'users', user.id), {
      role: roleName
    });
  }

  if (loading) return <Spinner />;

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Username</th>
          <th>Role</th>
          <th>Date joined</th>
          <th>Date last login</th>
          <th>Approved</th>
        </tr>
      </thead>

      <tbody>
        {users.map((user) =>
          <tr key={user.id}>
            <td className="align-middle">{user.username}</td>
            <td>
              <Form.Select
                onChange={(event) => handleUserRoleChange(user, event.target.value)}
                defaultValue={user.role ?? roles.USER.name}>
                {Object.values(roles).map(currentRole => <option>{currentRole.name}</option>)}
              </Form.Select>
            </td>
            <td className="align-middle">{dateObjectToDate(user.dateJoined, 'YYYY/MM/DD hh:mm')}</td>
            <td className="align-middle">{dateObjectToDate(user.dateLastLogin, 'YYYY/MM/DD hh:mm')}</td>
            <td className="align-middle">
              <Form.Check
                type="switch"
                checked={user.approved}
                onChange={() => handleApprovedChange(user)}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
