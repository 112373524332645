import "./App.scss";
import Signup from "./pages/authentication/Signup";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/authentication/Login";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import UpdateProfile from "./pages/authentication/UpdateProfile";
import NotFound from "./pages/error/NotFound";
import LoggedOutRoute from "./components/routes/LoggedOutRoute";
import NotApproved from "./pages/error/NotApproved";
import LeaderboardDetail from "./pages/leaderboard/LeaderboardDetail";
import UserManager from "./pages/admin/UserManager";
import PermissionRoute from "./components/routes/PermissionRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import { roles } from "./utils/Roles";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<LoggedOutRoute />}>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>

          <Route element={<AuthenticatedRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/not-approved" element={<NotApproved />} />
            <Route path="/leaderboard/:slug" element={<LeaderboardDetail />} />

            <Route element={<PermissionRoute permissionLevel={roles.EDITOR.permissionLevel} />}></Route>
            <Route element={<PermissionRoute permissionLevel={roles.ADMIN.permissionLevel} />}>
              <Route path="/users" element={<UserManager />} />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
