import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageTitle } from "../utils/PageTitle";
import Spinner from "../components/spinner/Spinner";
import { collection, doc, getCountFromServer, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../Firebase";
import { Leaderboard } from "../types/Leaderboard";
import { dateObjectToDate, timestampToDateObject } from "../utils/DateConversions";
import Empty from "../components/Empty";
import { hasPermission, roles } from "../utils/Roles";
import { useAuth } from "../contexts/AuthContext";

export default function Dashboard() {
  PageTitle(null);
  const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const addModalNameRef = useRef<HTMLInputElement>(null);
  const addModalSlugRef = useRef<HTMLInputElement>(null);
  const { currentUserDoc } = useAuth();

  useEffect(() => {
    const q = query(collection(db, 'leaderboards'), orderBy("dateLastUpdated", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data: Leaderboard[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        slug: doc.data().slug,
        dateCreated: timestampToDateObject(doc.data().dateCreated),
        dateLastUpdated: timestampToDateObject(doc.data().dateLastUpdated),
      }));

      setLeaderboards(data);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const addLeaderboard = async (name: string, slug?: string) => {
    let docRef = doc(collection(db, 'leaderboards'));
    if (slug === undefined || slug === "") slug = docRef.id;

    const leaderboardData: Leaderboard = {
      id: 'unused',
      name: name,
      slug: slug,
      dateCreated: new Date(),
      dateLastUpdated: new Date(),
    };

    await setDoc(docRef, leaderboardData);
  }

  /* const deleteLeaderboard = async () => {
    if (showDeleteModal != null) {
      await deleteDoc(doc(db, "leaderboards", showDeleteModal));

      setShowDeleteModal(null);
    }
  } */

  // modals
  const AddModal = () => {
    return (<>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add leaderboard</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="form-group">
              <Form.Label>Name</Form.Label>
              <Form.Control type="email" placeholder="Enter name" ref={addModalNameRef} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Slug (optional)</Form.Label>
              <Form.Control type="email" placeholder="Enter slug" ref={addModalSlugRef} />
              <Form.Text className="text-muted">
                Slug must be unique
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => handleAddModalSubmit()}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }

  const handleAddModalSubmit = async () => {
    let passed = true;
    setError('');

    if (addModalNameRef.current!.value === '') {
      passed = false;
      setError('Enter a name');
    }

    if (addModalSlugRef.current!.value !== '') {
      const countSnapshot = await getCountFromServer(query(collection(db, 'leaderboards'), where('slug', '==', addModalSlugRef.current!.value)))

      if (countSnapshot.data().count > 0) {
        passed = false;
        setError('Slug is already in use')
      }
    }

    if (passed) {
      addLeaderboard(addModalNameRef.current!.value, addModalSlugRef.current!.value.toLowerCase());
      setShowAddModal(false);
    }
  }

  if (loading) return <Spinner />;
  if (leaderboards.length === 0) return <Empty />;

  return (
    <>
      {hasPermission(roles.EDITOR.permissionLevel, currentUserDoc!.role) &&
        <div className="mb-4 d-flex justify-content-end">
          <Button onClick={() => setShowAddModal(true)}>Add</Button>
        </div>
      }
      <Row>
        {leaderboards.map((leaderboard, i) =>
          <Col md={4} className="mb-4" key={i}>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">{leaderboard.name}</h2>
                <strong>Last update:</strong> {dateObjectToDate(leaderboard.dateLastUpdated, 'YYYY/MM/DD hh:mm')}
                <Link to={'/leaderboard/' + leaderboard.slug} className="btn btn-primary w-100 mt-3 stretched-link">Detail</Link>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      <AddModal />
    </>
  )
}
